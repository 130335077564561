<template>
    <div class="text-center">
        <h4>Bet history</h4>
        <div class="text-center">
            <table class="justify-content-center">
                <thead>
                <th>game</th>
                <th>stake</th>
                <th>won</th>
            </thead>
            <tbody v-for="game in data" :key="game.id">
                <td>{{game.game_name}}</td>
                <td>{{game.stake}}</td>
                <td>{{game.won}}</td>
            </tbody>
            </table>
        </div>



    </div>
</template>
<script>
import bets from "@/services/betshistory";

export default {
    name: "CasinoHistory",
    data (){
        return{
            data:[],

        }
    },
    mounted(){
        // this.getBetsHistory();
        this.fetchBets();
    },
    methods:{
        fetchBets() {
            var vm = this;

            const path =  "https://casino.tucheze.com/history";
            console.log(this.getAuth())

            bets.get(path, {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': vm.getAuth()
                }
            })
                .then(response => {
                    this.data = response.data.data;
                    console.log('BETS Summary :', response.data);
                })
                .catch(err => {
                    console.error('Error Performance Summary :', err);
                });
        },

    }
}
</script>